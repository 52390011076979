import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from './Header.run'

import { LoginContext } from '../Context/Login.context'

class Header extends Component {

    componentDidMount() {
        HeaderRun();
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    render() {

        const { usuario, handleLogOut } = this.context;

        // console.log(JSON.stringify(usuario));

        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                            </div>
                            {/* <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                            </div> */}
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    {/* <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                                <em className="fas fa-bars"></em>
                            </a>
                            <a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <a  className="nav-link" onClick={ this.toggleUserblock }>
                                <em className="icon-user"></em>
                            </a>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <Link to="lock" title="Lock screen" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>
                        </li>
                    </ul> */}
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">

                        <li className="nav-item">
                            {/* <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a> */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>

                        {/* <li className="nav-item d-md-block">
                            <a className="nav-link" onClick={this.toggleUserblock}>
                                <em className="icon-user"></em>
                            </a>
                        </li> */}

                        { /* Fullscreen (only desktops) */}
                        <li className="nav-item d-md-block clickable">
                            <ToggleFullscreen className="nav-link" />
                        </li>
                        { /* START Alert menu */}
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret">
                                <em className="icon-bell"></em>
                                {(parseInt(usuario.solicitudes_pendientes) + parseInt(usuario.mensajes_pendientes) > 0) &&
                                    <span className="badge badge-danger">{parseInt(usuario.solicitudes_pendientes) + parseInt(usuario.mensajes_pendientes)}</span>
                                }
                            </DropdownToggle>
                            { /* START Dropdown menu */}
                            <DropdownMenu right className="dropdown-menu-right animated flipInX">
                                <DropdownItem>
                                    { /* START list group */}
                                    <ListGroup>
                                        {/* <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}> */}
                                        <ListGroupItem action>
                                            <Link to="/solicitudes" className="text-decoration-none">
                                                <div className="media">
                                                    <div className="align-self-start mr-2">
                                                        <em className="icon-star fa-1x text-purple"></em>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="m-0">Solicitudes Actualizadas</p>
                                                        <p className="m-0 text-muted text-sm">{parseInt(usuario.solicitudes_pendientes)} solicitudes actualizadas</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </ListGroupItem>
                                        {/* <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}> */}
                                        <ListGroupItem action>
                                            <Link to="/mensajes" className="text-decoration-none">
                                                <div className="media">
                                                    <div className="align-self-start mr-2">
                                                        <em className="icon-envelope fa-1x text-purple"></em>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="m-0">Nuevos Mensajes</p>
                                                        <p className="m-0 text-muted text-sm">{parseInt(usuario.mensajes_pendientes)} mensajes nuevos</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </ListGroupItem>
                                    </ListGroup>
                                    { /* END list group */}
                                </DropdownItem>
                                <p className="m-1 text-muted text-sm">Comprobación desde el último acceso</p>
                            </DropdownMenu>
                            { /* END Dropdown menu */}
                        </UncontrolledDropdown>
                        { /* END Alert menu */}

                        <li className="nav-item d-md-block">
                            <Link to="changepwd" title="Cambiar Contraseña" className="nav-link">
                                <em className="icon-key"></em>
                            </Link>
                        </li>
                        <li className="nav-item d-md-block">
                            {/* <Link to="lock" title="Cerrar Sesión" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link> */}
                            <Link to="login" onClick={handleLogOut} title="Cerrar Sesión" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>

                        </li>

                        { /* START Offsidebar button */}
                        {/* <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-notebook"></em>
                            </a>
                        </li> */}
                        { /* END Offsidebar menu */}
                    </ul>
                    { /* END Right Navbar */}

                    { /* START Search form */}
                    {/* <form className="navbar-form" role="search" action="search.html">
                       <div className="form-group">
                          <input className="form-control" type="text" placeholder="Type and hit enter ..."/>
                          <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                       </div>
                       <button className="d-none" type="submit">Submit</button>
                    </form> */}
                    { /* END Search form */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

Header.contextType = LoginContext;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);