/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import PageLoader from './components/Common/PageLoader';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'

import { deleteToken, initAxiosInterceptor } from './helpers/Auth-helper';

import { LoginContext } from './components/Context/Login.context'
import Login from './components/Pages/Login';
import axios from 'axios';

initAxiosInterceptor();

// const apiUrl = 'http://localhost:8080/reactlof_api';
const apiUrl = '../reactlof_api';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario: {},    // usuario: { name: 'PEDRO VELARDE BARCENA', groupName: 'DEPARTAMENTO 5' },
            cargandoUsuario: true
        };

        this.handleLogIn = this.handleLogIn.bind(this);
        this.handleLogOut = this.handleLogOut.bind(this);

    }

    async checkUserToken() {
        // console.log('checkUserToken')

        try {
            // const { data: usuario } = await axios.get(apiUrl + '/whoami.php');

            const { data: usuario } = await axios.get(apiUrl + '/index.php?op=whoami');

            this.setState({ usuario: { wid: usuario.wid, id: 
                usuario.id, 
                name: usuario.userName, 
                groupName: usuario.groupName, 
                presencia: usuario.presencia, 
                ultimo_acceso: usuario.ultimo_acceso, 
                editar_ticajes: usuario.editar_ticajes, 
                solicitudes_pendientes: usuario.solicitudes_pendientes, 
                mensajes_pendientes: usuario.mensajes_pendientes 
            }, cargandoUsuario: false });    

            console.log(usuario);

        } catch (error) {
            // console.log('checkUserToken error', error);
            this.setState({ usuario: {}, cargandoUsuario: false });
        };

    }

    componentDidMount() {
        // console.log('componentDidMount App')

        // este evento ocurre cuando se carga este componente (cuando se accede por primera vez a la Aplicación)
        // pero no se ejecuta cuando se navega entre las rutas de la Aplicación
        // por tanto viene bien para validar el UserToken en caso de que exista en el localstorage

        this.checkUserToken();

    }

    // async handleLogIn(usuario_email, usuario_password) {
    //     console.log('handleLogIn');

    //     try {

    //         const { data: usuario } = await axios.post(apiUrl + '/index.php', 'op=signin&email=' + usuario_email + '&password=' + usuario_password);

    //         //console.log('handleLogIn ', usuario);

    //         setToken(usuario.userToken);

    //         this.setState({ usuario: { wid: usuario.wid, id: usuario.id, name: usuario.userName, groupName: usuario.groupName, presencia: usuario.presencia, ultimo_acceso: usuario.ultimo_acceso }, cargandoUsuario: false, loginError: false });            

    //     } catch (error) {
    //         console.log('handleLogIn error', error);
    //         this.setState({ usuario: {}, cargandoUsuario: false, loginError: true });

    //         deleteToken();
    //     };

    // }

    async handleLogIn(usuario) {

        console.log('handleLogIn ', usuario);

        this.setState({ usuario: { wid: usuario.wid, 
            id: usuario.id, 
            name: usuario.userName, 
            groupName: usuario.groupName, 
            presencia: usuario.presencia, 
            ultimo_acceso: usuario.ultimo_acceso, 
            editar_ticajes: usuario.editar_ticajes, 
            solicitudes_pendientes: usuario.solicitudes_pendientes, 
            mensajes_pendientes: usuario.mensajes_pendientes 
        } });

    }

    handleLogOut() {
        // console.log('handleLogOut');

        deleteToken();

        this.setState({ usuario: {} });

    }

    render() {
        // console.log('render App')

        const user = {
            usuario: this.state.usuario,
            handleLogIn: this.handleLogIn,
            handleLogOut: this.handleLogOut,
        }

        // specify base href from env varible 'PUBLIC_URL'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global PUBLIC_URL */
        const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

        if (this.state.cargandoUsuario) {
            return <PageLoader />
        } else {

            return (

                <LoginContext.Provider value={user}>
                    <BrowserRouter basename={basename}>
                        {/* <Routes usuario={this.state.usuario} /> */}
                        {/* <Routes /> */}

                        {/* {!this.state.usuario.name ? <Login /> : <button onClick={this.handleLogOut}>salir de sesion</button>} */}

                        {/* {!this.state.usuario.name ? <Login /> : <DashboardV1/> */}

                        {/* {!this.state.usuario.name ? <Login loginError={this.state.loginError} /> : <Routes />} */}
                        {!this.state.usuario.name ? <Login /> : <Routes />}

                    </BrowserRouter>

                </LoginContext.Provider>

            )
        }

    }
}

export default App;
