const Menu = [

    {
        heading: ' ',        
    },
    {
        name: 'Cuadro de Mando',
        icon: 'icon-grid',
        path: '/cuadromando'
    },
    {
        name: 'Calendario',
        icon: 'icon-calendar',
        path: '/calendario'
    },
    {
        name: 'Presencia',
        icon: 'icon-clock',
        path: '/presencia'
    },
    {
        name: 'Solicitudes',
        icon: 'icon-star',
        path: '/solicitudes'
    },
    {
        name: 'Mensajes',
        icon: 'icon-envelope',
        path: '/mensajes'
    }
];

export default Menu;