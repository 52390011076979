import axios from 'axios';

const TOKEN_KEY = 'LOF_TOKEN';

export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken() {
    localStorage.removeItem(TOKEN_KEY);
}

export function initAxiosInterceptor() {

    // axios.defaults.headers.get['Accept'] = 'application/json';
    // axios.defaults.headers.get['Content-Type'] = 'application/json';
    // axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.headers.get['Access-Control-Allow-Credentials'] = 'true';    
    // axios.defaults.headers.get['Access-Control-Allow-Methods'] = 'POST,GET,OPTIONS, PUT, DELETE';
    // axios.defaults.headers.get['Access-Control-Allow-Headers'] = 'Accept, Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token, Authorization'; 

    // console.log('initAxiosInterceptor');

    axios.interceptors.request.use(function (config) {
        const token = getToken();

        if (token) {
            // config.headers.Authorization = `bearer ${token}`;
            config.headers.Authorization = token;
        }

        return config;
    });

    axios.interceptors.response.use(

        function (response ) {

            // response.headers['Access-Control-Allow-Origin'] = '*';
            // response.headers['Access-Control-Allow-Credentials'] = 'true';
            // response.headers['Access-Control-Request-Method'] = 'GET';

            // console.log(response.data);

            return response;
        },
        function (error) {
            // return Promise.reject(error);
            throw error;
        }

    );    


    // axios.interceptors.response.use((response) => {
    //     return response
    //   }, async function (error) {
    //     // const originalRequest = error.config;
    //     // if (error.response.status === 403 && !originalRequest._retry) {
    //     //   originalRequest._retry = true;
    //     //   const access_token = await refreshAccessToken();            
    //     //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    //     //   return axiosApiInstance(originalRequest);
    //     // }
    //     return Promise.reject(error);
    //   });

    // axios.interceptors.response.use(
    //     res => res,
    //     err => {
    //         if (err.response.status === 401) {
    //             throw new Error(`${err.config.url} not found`);
    //         }
    //         throw err;
    //     }
    // );

}