import React, { Component } from 'react';
import { Input } from 'reactstrap';
import axios from 'axios';
import swal from 'sweetalert';
import { setToken, deleteToken } from '../../helpers/Auth-helper';
import { LoginContext } from '../Context/Login.context'

// const apiUrl = 'http://localhost:8080/reactlof_api';
const apiUrl = '../reactlof_api';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            validandoUsuario: false,
            loginError: false,
            loginErrorStatus: ''
        };

    }

    mostrarMensajeError(mensaje, icon) {
        swal({
            title: mensaje,
            icon: icon,
            buttons: {
                confirm: {
                    text: 'Cerrar',
                    value: true,
                    visible: true,
                    className: "bg-purple",
                    closeModal: true
                },
            }
        })        
    }

    HandleClickLogin = async (e) => {

        // console.log('HandleClickLogin');

        e.preventDefault();

        this.setState({ loginError: false, validandoUsuario: true });

        const { handleLogIn } = this.context;

        try {
            const { data: usuario } = await axios.post(apiUrl + '/index.php', 'op=signin&email=' + this.state.email + '&password=' + this.state.password);

            //console.log('handleLogIn ', usuario);

            setToken(usuario.userToken);

            handleLogIn(usuario);

        } catch (error) {
            // console.log('handleLogIn error', error);

            this.setState({ loginError: true, loginErrorStatus: error.response.status, validandoUsuario: false });

            deleteToken();
        };

    }

    HandleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    HandleChangePassword = (e) => {
        this.setState({ password: e.target.value });
    }

    HandleClickReenviarPassword = async (e) => {
        // console.log("reenviar password");

        if (!this.state.email) {
            this.mostrarMensajeError("Debe indicar el email antes de solicitar el reenvío", 'warning');
        } else {

            try {
                const { data: response } = await axios.post(apiUrl + '/index.php', 'op=reenviar_password&email=' + this.state.email);
    
                // console.log(response);
    
                if (response.response !== "ok") {
                    this.mostrarMensajeError("No ha sido posible enviar la password", "warning", false);
                } else {
                    this.mostrarMensajeError("Le hemos enviado la password a su correo electrónico", "success", true);
                }
    
            } catch (error) {
                this.mostrarMensajeError(error, 'warning');
            };
    
        }

    }

    render() {

        const { loginError, loginErrorStatus } = this.state;

        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center">
                        <a href="">
                            <img className="block-center rounded" src="img/logo_xl.png" alt="Logo" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h4 className="text-center">PORTAL DEL EMPLEADO</h4>
                        <p className="text-center pv">Versión 2020.08.01 (2)</p>
                        <form className="mb-3" name="formLogin">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="Email"
                                        onChange={this.HandleChangeEmail}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="password"
                                        name="password"
                                        className="border-right-0"
                                        placeholder="Password"
                                        onChange={this.HandleChangePassword}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-lock"></em>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-block btn-purple mt-3" onClick={this.HandleClickLogin} disabled={this.state.validandoUsuario}>Acceder</button>

                        </form>

                        {(!this.state.validandoUsuario && loginError) && <div className="alert alert-danger fade show" role="alert">{(loginErrorStatus === "402") ? "Debe renovar su subscripción" : "Usuario y/o password no válidos"}</div>}

                        <p className="pt-3 text-center">¿ Olvidó su password ?</p>
                        {/* <Link to="register" className="btn btn-block btn-secondary">Reenviar Password</Link> */}
                        <button className="btn btn-block btn-secondary" onClick={this.HandleClickReenviarPassword} disabled={this.state.validandoUsuario}>Reenviar Password</button>

                    </div>
                </div>
                <div className="p-3 text-center">
                    <span className="mr-2">&copy; {new Date().getFullYear()} LaborOfficeFree</span>
                </div>
            </div>
        );
    }
}

Login.contextType = LoginContext;

export default Login;
